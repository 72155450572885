import React, { useState, useEffect } from 'react';
import './RotatingWords.css'; // Optional: If you want to style the navbar externally.


const RotatingWords = () => {
  const words = [
    "Empower",
    "Inspire",
    "Live",
    "Engage",
    "Connect",
  ];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500); // Change word every 2 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <span>{words[currentWordIndex]}</span>
  );
};

export default RotatingWords;
