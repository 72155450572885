import React, { useState } from 'react';
import './NavBar.css'; // Optional: If you want to style the navbar externally.

import logo from '../assets/DT Logo Light.png'; // Replace with your actual logo path


const NavBar = ({ scrollToSection }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <a href="#home">
          <img src={logo} alt="Logo" className="logo" />
        </a>
      </div>
      <button className="hamburger" onClick={toggleNav}>
         ☰
      </button>
      <ul className={`nav-links ${isNavOpen ? 'active' : ''}`}>
        <li><a href="#home" onClick={() => scrollToSection('#home')}>Home</a></li>
        <li><a href="#what-we-do" onClick={() => scrollToSection('#what-we-do')}>What We Do</a></li>
        <li><a href="#what-we-are" onClick={() => scrollToSection('#what-we-are')}>What We Are</a></li>
        <li><a href="#what-we-plan-to-do" onClick={() => scrollToSection('#what-we-plan-to-do')}>What We Plan To Do</a></li>
        <li><a href="#reach-out-to-us" onClick={() => scrollToSection('#reach-out-to-us')}>Reach Out To Us</a></li>
      </ul>
    </nav>
  );
};

export default NavBar;