// src/App.js
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";  // Import fade effect CSS
import "./App.css";  // Custom styling

// Components
import NavBar from './components/NavBar';  // Adjust the path if necessary
import RotatingWords from './components/RotatingWords';  // Import the new component

// Background slide show images
import img1 from './assets/DT animated rectangular 1.webp';
import img2 from './assets/DT animated rectangular 2.webp';
import img3 from './assets/DT animated rectangular 3.webp';
import img4 from './assets/DT animated rectangular 5.webp';
import img5 from './assets/DT animated rectangular 6.webp';
import img6 from './assets/DT animated rectangular city 1.webp';
import img7 from './assets/DT animated rectangular city 2.webp';
import img8 from './assets/DT animated rectangular city 3.webp';
import img9 from './assets/DT animated rectangular city 4.webp';
import img10 from './assets/DT animated rectangular coffee shop.webp';
import img11 from './assets/DT animated rectangular game.webp';
import img13 from './assets/DT animated rectangular family.jpg';
import img14 from './assets/DT animated rectangular date night.webp';
import img15 from './assets/DT animated rectangular bus stop.webp';


// Other Images
import imgRan1 from './assets/Casey Sam at CWRU.jpg';
import imgCollegeCampus from './assets/DT college campus.png'
import imgCollegeCampus2 from './assets/DT college campus 2.png'
import imgCollegeCampus3 from './assets/DT college campus 3.png'
import imgCollegeCampus4 from './assets/DT cwru.jpg'



function App() {
  const [isLogoVisible, setIsLogoVisible] = useState(false); // Track logo visibility

  useEffect(() => {
    // Observer to hide the logo when the first section is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsLogoVisible(false); // Hide logo when the first section is visible
            console.log("First section is visible, hiding logo");
          } else {
            setIsLogoVisible(true); // Show logo when the first section is not visible
            console.log("First section is not visible, showing logo");
          }
        });
      },
      { threshold: 0.5 } // Adjust this threshold if necessary
    );

    const firstSection = document.querySelector(".first-section");

    if (firstSection) {
      observer.observe(firstSection); // Observe the first section
    }

    // Cleanup the observer on unmount
    return () => {
      if (firstSection) {
        observer.unobserve(firstSection);
      }
    };
  }, []);

    // Function to handle scrolling to the next section
    const scrollToNextSection = () => {
      const nextSection = document.querySelector('.info-section');
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' });
      }
    };

  return (
    <div className="App">

      <NavBar scrollToSection={scrollToNextSection}/>

      <div className="section first-section" id="home">
          <div className={`big-text-container hide ${!isLogoVisible ? "visible" : "hidden"}`}>
            <h1 className="big-text">
                <h1>
                  <span className="special-text">Disconnect Tech</span>
                </h1>
            </h1>
          </div>

          <div className={`arrow-down-container hide ${!isLogoVisible ? "visible" : "hidden"}`} onClick={scrollToNextSection}>
            <div className="arrow-down"></div>
          </div>
        <Swiper
          modules={[Navigation, Autoplay, EffectFade]}  // Ensure EffectFade is included
          spaceBetween={0}
          slidesPerView={1}
          loop={true}  // Infinite loop
          autoplay={{ delay: 1800, disableOnInteraction: false }}
          effect="fade"  // Ensure the fade effect is applied
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={img14} alt="Slide 14" className="slide-image" />
          </SwiperSlide>
          
          <SwiperSlide>
            <img src={img5} alt="Slide 5" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={img10} alt="Slide 9" className="slide-image" />
          </SwiperSlide>
          
          <SwiperSlide>
            <img src={img8} alt="Slide 8" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={img2} alt="Slide 2" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={img11} alt="Slide 9" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={img3} alt="Slide 3" className="slide-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={img6} alt="Slide 6" className="slide-image" />
          </SwiperSlide>
        </Swiper>
      </div>


      {/* What We Do Section */}
      <div id="what-we-do" className="section">
        <p>Join us to <RotatingWords />.</p>
      </div>

      {/* What We Are Section */}
      <div id="what-we-are" className="section">
        <div className="content-container">
          <div className="text-content">
            <h1>What We Are</h1>
            <p>
            Disconnect Tech is dedicated to addressing the challenges posed by 
            the recent and widespread integration of technology into our 
            daily lives.
            </p>

            <p>
            The overuse of screens can have far-reaching consequences, 
            from diminished face-to-face communication skills to weakened
             interpersonal connections, as well as anxiety, depression, and
             reduced productivity.
            </p>

            <p>
            We are not an anti-technology organization. We emphasize the
             importance of balance and intentional use of technology to
              prevent it from undermining the quality of life.
            </p>

            <p>
            Disconnect Tech is a movement. What we are is written 
            in our name. To reconnect to what matters most, we must
             first disconnect.
            </p>
          </div>
          <div className="image-content">
            {/* Placeholder for images or other visual content */}
            <img src={imgCollegeCampus4} alt="Mission Visual" className="mission-image" />
          </div>
        </div>
      </div>

      {/* What We Plan To Do Section */}
      <div id="what-we-plan-to-do" className="section">
        <div className="content-container">
          <div className="image-content">
            {/* Placeholder for images or other visual content */}
            <img src={imgCollegeCampus2} alt="Mission Visual" className="mission-image" />
          </div>
          <div className="text-content">
            <h1>What We Plan To Do</h1>
            <h2>
              Short Term
            </h2>
            <p>
            At Disconnect Tech CWRU, we will host informative sessions, 
            device-free activities, speaker series, and many more other 
            engaging events.
            </p>

            <p>
              We intend to gain the trust, recognition, and support of the
               student and faculty population at Case Western Reserve
                University.
            </p>

            <h2>
              Long Term
            </h2>
            <p>
              Because we believe that the issue is widespread and
               far-reaching, the solution must also be widespread
               and far-reaching. We intend to harness the power and 
               enthusiasm of young people nationwide to modify the 
               current social norms surrounding technology use.
            </p>

            <p>
              We will develop a screen time tracker app intended for 
              college students and colleges around the United States. The 
              colleges with the best habits will be rewarded monetarily to
              donate to a charitable cause of their choice.
            </p>
          </div>
        </div>
      </div>

      {/* Reach Out To Us Section */}
      <div id="reach-out-to-us" className="section">
          <h1>Reach Out To Us</h1>
          <p>Contact information and ways to get involved with Disconnect Tech.</p>

          <div className="contact-container">
            <div className="contact-item">
              <a href="https://www.linkedin.com/company/disconnect-tech" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
            </div>
            <div className="contact-item">
              <a href="https://www.instagram.com/disconnecttech" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </div>
            <div className="contact-item" id="casey">
              <a href="mailto:casey.malcolm@case.edu">
                <i className="fas fa-envelope"></i> casey.malcolm@case.edu
              </a>
            </div>
          </div>
       </div>


      
    </div>
  );
}

export default App;